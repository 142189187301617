import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Components/Footer";
import InnerBanner from "../Components/InnerBanner";
import NavBar from "../Components/NavBar";
import Button from "react-bootstrap/Button";

import Saloon from "../Assets/Images/saloon.png";
import Estate from "../Assets/Images/estate.png";
import Suv from "../Assets/Images/suv.png";
import Minivan from "../Assets/Images/minivan.png";

import PaxIcon from "../Assets/Images/pax-icon.png";
import LugIcon from "../Assets/Images/lug-icon.png";
import SeatIcon from "../Assets/Images/child-seat-icon.png";
import Form from "react-bootstrap/Form";
import { getRequest } from "../Helpers/APIHelper";
import { useLocation, useNavigate } from "react-router-dom";
import ForSeo from "../Components/seo/ForSeo";

const VehicleSelect = (props) => {
  const location = useLocation();
  const currentURL = window.location.pathname;
  const navigate = useNavigate();

  const [fleetList, setFleetList] = useState([]);
  const [quote, setQuote] = useState([]);

  const [seo, setSeo] = useState({});
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  useEffect(() => {
    var quote = JSON.parse(localStorage.getItem("quote"));
    setQuote(quote);
    setFleetList(quote?.fleets ?? []);
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? props?.title}
        description={seo?.description ?? props?.desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={siteURL ?? ""}
      />
      <InnerBanner quote={quote} />
      <div className="vehicle-select-wrapper section-gap">
        <Container>
          <Row>
            {fleetList.length
              ? fleetList.map((fleet, index) => (
                  <Col key={index} lg={12} className="single-vehicle-select">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="row">
                          <img
                            className="vehicle-show"
                            src={fleet?.image_link ?? ""}
                            alt="fleet"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <h3>{fleet.title}</h3>
                        <div className="capacity-wrap">
                          <span>
                            <img src={PaxIcon} alt="capacity" />{" "}
                            {fleet.max_passenger}
                          </span>
                          <span>
                            <img src={LugIcon} alt="capacity" />{" "}
                            {fleet.max_luggage}
                          </span>
                          <span>
                            <img src={SeatIcon} alt="capacity" />{" "}
                            {fleet.max_baby_seat}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="trip-price-wrapper">
                          <p>Your Trip Price</p>
                          <h2>
                            {props?.profileInfo?.currency ?? ""}{" "}
                            {fleet?.fare_data?.grand_total ?? 0}
                            {/* <sub>Inc. GST</sub> */}
                          </h2>
                          <button
                            className="custom-btn"
                            variant="main"
                            type="submit"
                            onClick={() => {
                              localStorage.setItem(
                                "selected_fleet_id",
                                JSON.stringify(fleet?.fleet_category_id ?? "")
                              );
                              if (currentURL === "/iframe/vehicle-select") {
                                navigate("/iframe/booking-form");
                              } else {
                                navigate("/booking-form");
                              }
                            }}
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              : ""}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default VehicleSelect;
