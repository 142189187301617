import React, { useState } from "react";

import Saloon from "../Assets/Images/saloon.png";
import PaxIcon from "../Assets/Images/pax-icon.png";
import LugIcon from "../Assets/Images/lug-icon.png";
import SeatIcon from "../Assets/Images/child-seat-icon.png";
import moment from "moment";
import Map from "./Map";

const InnerBannerNext = ({ quote, fleet }) => {
  return (
    <>
      <section className="inner-banner">
        {quote.from_lat && (
          <Map
            origin={{ lat: quote.from_lat, lng: quote.from_lng }}
            destination={{ lat: quote.to_lat, lng: quote.to_lng }}
            waypoints={quote.via}
          />
        )}
        <div className="ride-info">
          <div className="ride-main-details">
            <h1>Select Your Fleet</h1>
            <div className="route-info">
              <p>{quote?.from_location ?? ""}</p>
              {quote?.via &&
                quote?.via.map((point) => (
                  <p className="via-point">{point?.location}</p>
                ))}
              {quote?.to_location && <p>{quote?.to_location ?? ""}</p>}
            </div>
            {quote?.service_type == "point_to_point" ? (
              <p className="distance-duration">
                <span>
                  Est. Distance <strong>{quote?.distance ?? ""}</strong>
                </span>
                <span>
                  Est. Time <strong>{quote?.duration ?? ""}</strong>
                </span>
              </p>
            ) : (
              <p className="distance-duration">
                <span>
                  {" "}
                  Duration <strong>{quote?.hourly_duration ?? ""} Hr</strong>
                </span>
              </p>
            )}
            <div className="date-time-wrapper">
              <div className="date-wrap">
                {moment(quote.pickup_datetime).format("DD")}
                <span>
                  {moment(quote.pickup_datetime).format("MMM")}
                  <strong>
                    {moment(quote.pickup_datetime).format("YYYY")}
                  </strong>
                </span>
              </div>
              <div className="time-wrap">
                <strong>
                  {" "}
                  {moment(quote.pickup_datetime).format("HH:mm A")}
                </strong>
                {moment(quote.pickup_datetime).format("dddd")}
              </div>
            </div>
            {quote.return_datetime && (
              <div className="date-time-wrapper mt-2">
                <div className="date-wrap">
                  {moment(quote.return_datetime).format("DD")}
                  <span>
                    {moment(quote.return_datetime).format("MMM")}
                    <strong>
                      {moment(quote.return_datetime).format("YYYY")}
                    </strong>
                  </span>
                </div>
                <div className="time-wrap">
                  <strong>
                    {" "}
                    {moment(quote.return_datetime).format("HH:mm A")}
                  </strong>
                  {moment(quote.return_datetime).format("dddd")}
                </div>
              </div>
            )}
            <div className="selected-vehicle">
              <div className="selected-fleet-ctn">
                <img src={fleet?.image_link ?? ""} alt="" />
              </div>
              <div>
                <h5> {fleet?.title ?? ""} </h5>
                <div className="capacity-wrap">
                  <span>
                    <img src={PaxIcon} alt="" /> {fleet?.max_passenger ?? ""}
                  </span>
                  <span>
                    <img src={LugIcon} alt="" /> {fleet?.max_luggage ?? ""}
                  </span>
                  <span>
                    <img src={SeatIcon} alt="" /> {fleet?.max_baby_seat ?? ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InnerBannerNext;
