import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import googlePlayLogo from "../Assets/Images/g-play.png";
import appStoreLogo from "../Assets/Images/app-store.webp";
import Banner from "../Assets/Images/banner.jpeg";
import Quote from "./Quote";
import { Link } from "react-router-dom";
import { useDataContext } from "../context/dataContext";

const MainBanner = () => {
  const { profileData } = useDataContext();

  console.log(profileData);
  return (
    <>
      <section className="main-banner">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="banner-text">
                <h4>Let’s GET</h4>
                <h1>Free Quote</h1>
              </div>
              <Quote />
            </Col>

            <Col lg={6}>
              <div className="banner-img pt-5">
                <img src={Banner} alt="" />
              </div>
              {profileData?.android_app_link && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <h4>Download Our App</h4>
                  <div className="download-section">
                    <Link target="_blank" to={profileData?.android_app_link}>
                      <img
                        style={{
                          height: "55px",
                          width: "170px",
                          objectFit: "contain",
                        }}
                        width={200}
                        src={googlePlayLogo}
                        alt="app-store"
                      />
                    </Link>
                    <Link target="_blank" to={profileData?.ios_app_link}>
                      <img
                        style={{
                          objectFit: "contain",
                          width: "170px",
                          height: "55px",
                        }}
                        width={200}
                        src={appStoreLogo}
                        alt="google-play"
                      />
                    </Link>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MainBanner;
