import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AirportTransfer from "../Assets/Images/airport-transfer.png";
import CorporateTransfer from "../Assets/Images/corporate-transfer.png";
import CityTransfer from "../Assets/Images/city-transfer.png";
import HotelTransfer from "../Assets/Images/hotel-transfer.png";
import { getRequest } from "../Helpers/APIHelper";

const FourSectionArrow = ({ features = null, aboutInfo }) => {
  const featureList = [
    {
      title: "Ride On Time",
    },
    {
      title: "Experience Driver",
    },
    {
      title: "Best Reasonable Rate",
    },
    {
      title: "Multiple Fleet Options",
    },
  ];

  if (!features) features = featureList;
  return (
    <>
      <section className="about-feature-wrapper section-gap pb-4">
        <Container>
          <h3 className="section-title">About Us</h3>
          <div dangerouslySetInnerHTML={{ __html: aboutInfo ?? "" }}></div>
          <Row className="mt-5 pt-5">
            {features.map((feature, index) => (
              <Col key={index} lg={3}>
                <div className="arrow-feature">
                  <h4>{feature.title}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FourSectionArrow;
