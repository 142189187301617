import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import InnerBannerNext from "../Components/InnerBannerNext";
import Modal from "react-bootstrap/Modal";
import { getRequest, postRequest } from "../Helpers/APIHelper";
import ForSeo from "../Components/seo/ForSeo";

const Confirmation = ({ title, desc, profileInfo }) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(true);
  const [isReturnVisible, setIsReturnVisible] = useState(false);
  const [isMeetAndGreet, setIsMeetAndGreet] = useState(false);
  const [isReturnMeetAndGreet, setIsReturnMeetAndGreet] = useState(false);

  // -------for-seo-----
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  const currentURL = window.location.pathname;

  useEffect(() => {
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };
  // ----------------------

  const [quote, setQuote] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [fareData, setFareData] = useState([]);
  const [selected_fleet, setSelectedFleet] = useState(null);
  const [formData, setFormData] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const selected_fleet_id = JSON.parse(
      localStorage.getItem("selected_fleet_id")
    );
    var quote = JSON.parse(localStorage.getItem("quote"));
    setQuote(quote);
    var bookingData = JSON.parse(localStorage.getItem("booking_data"));
    setBookingData(bookingData);
    var fareData = JSON.parse(localStorage.getItem("fare_data"));
    setFareData(fareData);

    bookingData.fare_data=fareData;
    setFormData(bookingData);

    const filteredData =
      quote.fleets.filter(
        (item) => item.fleet_category_id === selected_fleet_id
      )[0] ?? null;
    setSelectedFleet(filteredData);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnDisable(true);
    postRequest("quote/finish", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          localStorage.removeItem("quote");
          localStorage.removeItem("bookingData");
          localStorage.removeItem("fareData");
          if (response?.data?.paypal_redirect_link ?? false) {
            window.location.href = response?.data?.paypal_redirect_link;
          } else {
            localStorage.setItem("thankYouMsg", response?.message ?? "");
            if (currentURL === "/iframe/booking-form") {
              navigate(
                "/iframe/thank-you?booking_ref=" +
                  (response?.data?.booking_ref ?? "")
              );
            } else {
              navigate(
                "/thank-you?booking_ref=" + (response?.data?.booking_ref ?? "")
              );
            }
          }
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? title}
        description={seo?.description ?? desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={siteURL ?? ""}
      />
      {btnDisable ? <div className="loading"></div> : ""}
      {/* <NavBar SeoTitle={title} SeoDesc={desc} /> */}
      <InnerBannerNext quote={quote} fleet={selected_fleet} />

      <div class="booking-form-wrapper section-gap">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="booking-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <h4 className="mt-0">Personal Details</h4>
                      <p>Full Name: <strong>{capitalizeFirstLetter(bookingData.first_name)} {capitalizeFirstLetter(bookingData.last_name)}</strong></p>
                      <p>Email: <strong>{bookingData.email}</strong></p>
                      <p>Contact Number: <strong>{bookingData.phone}</strong></p>
                    </Col>
                    {quote?.lead_passenger_name && quote.lead_passenger_name !== '' && (
                      <div>
                        <Col lg={12}>
                          <h4>Booking For Someone Else</h4>
                          <p>Lead Passenger Name: <strong>{bookingData.lead_passenger_name}</strong></p>
                          <p>Lead Passenger Number: <strong>{bookingData.lead_passenger_phone}</strong></p>
                        </Col>
                      </div>
                    )}
                    <Col lg={12}>
                      <h4>Capacity & Extras</h4>
                      <p>
                        Passenger: <strong>{bookingData?.passenger??1}</strong>, {" "}
                        Luggage: <strong>{bookingData?.luggage??0}</strong>, {" "}
                        Child Seat: <strong>{bookingData?.baby_seat??0}</strong> {" "}
                      </p>
                    </Col>

                    {quote?.is_from_airport === 1 && (
                      <>
                        <Col lg={12}>
                          <h4>Flight Information</h4>
                          <p>Airlines: <strong>{bookingData.airlines}</strong></p>
                          <p>Flight Name: <strong>{bookingData.flight_name}</strong></p>
                          <p>Flight Number: <strong>{bookingData.flight_number}</strong></p>
                        </Col>
                      </>
                    )}

                    {quote?.is_return_airport === 1 && (
                      <div>
                        <Col lg={12}>
                          <h4>Return Flight Information</h4>
                          <p>Airlines: <strong>{bookingData.return_airlines}</strong></p>
                          <p>Flight Name: <strong>{bookingData.return_flight_name}</strong></p>
                          <p>Flight Number: <strong>{bookingData.return_flight_number}</strong></p>
                        </Col>
                      </div>
                    )}
                    <Col lg={12}>
                      <h4>Note To Driver</h4>
                      <p><strong>{bookingData?.driver_note !==''?bookingData?.driver_note:'-'}</strong></p>
                    </Col>
                    <Col lg={12}>
                      <h4>Payment Method</h4>
                      <p><strong>{capitalizeFirstLetter(bookingData.payment_method)} Payment</strong></p>
                    </Col>
                    <Col lg={12}>
                      <h4>Fare Info</h4>
                      <Row>
                      <Col lg={6}>
                        <p><strong> {quote?.journey_type === 'two_way'?'One Way':''} Fare Breakdown</strong></p>
                        <p>Base Fare: <strong> {profileInfo?.currency ?? ""}{fareData?.fare??0} </strong></p>
                        {fareData?.additional?.charges && fareData.additional?.charges?.length > 0 ? (
                          fareData.additional.charges.map(({ name, value }) => (
                            <p key={name}>{name}: <strong>{profileInfo?.currency ?? ""}{value}</strong></p>
                          ))
                        ) : (
                         ""
                        )}
                        <p><strong>Total Fare:{profileInfo?.currency ?? ""}{fareData?.total??0}</strong></p>
                      </Col>
                      {quote?.journey_type === 'two_way' && (
                        <>
                        <Col lg={6}>
                          <p><strong>Two Way Fare Breakdown</strong></p>
                          <p>Base Fare: <strong> {profileInfo?.currency ?? ""}{fareData?.return_fare??0} </strong></p>
                          {fareData?.return_additional?.charges && fareData.return_additional?.charges?.length > 0 ? (
                            fareData.return_additional.charges.map(({ name, value }) => (
                              <p key={name}>{name}: <strong>{profileInfo?.currency ?? ""}{value}</strong></p>
                            ))
                          ) : (
                          ""
                          )}
                          <p><strong>Total Fare: {profileInfo?.currency ?? ""}{fareData?.return_total??0}</strong></p>
                        </Col>
                        <Col lg={12}>  
                          <h4>Grand Total:{profileInfo?.currency ?? ""}{fareData?.grand_total??0}</h4>
                        </Col>
                      </>
                      )}
                      </Row>
                    </Col>
                  </Row>
                  <Col lg="12">
                    <p>
                      By clicking the "Submit" button, you agree to Our{" "}
                      <Link
                        className="text-link"
                        to="/terms-of-services"
                        target="_blank"
                      >
                        Terms of services
                      </Link>{" "}
                      &
                      <Link
                        className="text-link"
                        to="/privacy-policy"
                        target="_blank"
                      >
                        &nbsp; Privacy Policy
                      </Link>
                    </p>
                  </Col>
                  <Col lg={{ span: 4, offset: 4 }} className="pt-4">
                    <button className="custom-btn" variant="main" type="submit">
                      Submit
                    </button>
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Confirmation;
