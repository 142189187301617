import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SUV from "../Assets/Images/new-suv.png";
import Call from "../Assets/Images/call-action.png";
import Email from "../Assets/Images/email-action.png";
import { Link } from "react-router-dom";
import { getRequest } from "../Helpers/APIHelper";
import { config } from "../Helpers/Constants";
import FormatPhoneNumber from "../Helpers/FormatPhoneNumber";

const CallToBook = () => {
  const demoDomain = config.DEMO_DOMAIN;
  const domainName = window.location.hostname;
  const [profile, setProfile] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getProfile();
    }
  }, []);
  const getProfile = () => {
    getRequest("profile")
      .then((response) => {
        setProfile(response?.profile ?? []);
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <div className="call-to-book-wrapper">
        <Container>
          <Row>
            <Col lg={5}>
              <img className="car-name" src={SUV} alt="" />
            </Col>
            <Col lg={7}>
              <h5>Call Us to Book</h5>
              {domainName === demoDomain ? (
                <Row>
                  <Col lg={6}>
                    <Link style={{ fontSize: "16px" }}>
                      <p style={{ padding: "0px", margin: "0px" }}>
                        A: 1111B South Governors Avenue Dover, DE 19904 US,
                      </p>
                    </Link>
                    <Link to="tel:+13028838908" style={{ fontSize: "16px" }}>
                      <p style={{ padding: "0px", margin: "0px" }}>
                        P: {FormatPhoneNumber("+19172591394")} (Call/SMS)
                      </p>
                    </Link>
                    {/* <Link
                      to="mailto:support@urride.online"
                      style={{ fontSize: "16px" }}
                    >
                      <p style={{ padding: "0px", margin: "0px" }}>
                        E: support@urride.online
                      </p>
                    </Link> */}
                  </Col>
                  <Col lg={6}>
                    {/* <Link style={{ fontSize: "16px" }}>
                      <p style={{ padding: "0px", margin: "0px" }}>
                        A: Laltipur, 44700, Nepal
                      </p>
                    </Link> */}
                    <Link to="tel:+9779810101016" style={{ fontSize: "16px" }}>
                      <p style={{ padding: "0px", margin: "0px" }}>
                        P: {FormatPhoneNumber("+9779810101016")} (whatsapp)
                      </p>
                    </Link>
                    <Link
                      to="mailto:support@urride.online"
                      style={{ fontSize: "16px" }}
                    >
                      <p style={{ padding: "0px", margin: "0px" }}>
                        E: support@urride.online
                      </p>
                    </Link>
                  </Col>
                </Row>
              ) : (
                <>
                  <img style={{ width: "100px" }} src={Call} alt="" />
                  <Link to={"tel:" + profile?.mobile ?? ""}>
                    {/* {profile?.mobile ?? ""} */}
                    {profile?.mobile && FormatPhoneNumber(profile?.mobile)}
                  </Link>
                  <br />
                  <img style={{ width: "100px" }} src={Email} alt="" />
                  <Link to={"mailto:" + profile?.email ?? ""}>
                    {profile?.email ?? ""}
                  </Link>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CallToBook;
