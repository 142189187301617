import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ForSeo from "../Components/seo/ForSeo";
import { getRequest } from "../Helpers/APIHelper";

const ThankYou = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const booking_ref = JSON.parse(searchParams.get("booking_ref"));
  // -------for-seo-----
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  const currentURL = window.location.pathname;

  useEffect(() => {
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };

  useEffect(() => {
    setMessage(localStorage.getItem("thankYouMsg"));
    localStorage.removeItem("thankYouMsg");
  }, []);

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? props?.title}
        description={seo?.description ?? props?.desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={canonicalURL ?? ""}
      />
      <div className="thankyou-banner section-gap inner-banner">
        <Container>
          <h1>Your Booking #{booking_ref} request has submitted.</h1>
          <p>{message}</p>
          {currentURL === "/iframe/thank-you" ? (
            <Link
              onClick={() => localStorage.removeItem("quote")}
              to="/iframe"
              className="btn-sm btn-main"
            >
              Go to Homepage
            </Link>
          ) : (
            <Link
              onClick={() => localStorage.removeItem("quote")}
              to="/"
              className="btn-sm btn-main"
            >
              Go to Homepage
            </Link>
          )}
        </Container>
      </div>
    </>
  );
};

export default ThankYou;
