import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AirportTransfer from "../Assets/Images/airport-transfer.png";
import CorporateTransfer from "../Assets/Images/corporate-transfer.png";
import CityTransfer from "../Assets/Images/city-transfer.png";
import HotelTransfer from "../Assets/Images/hotel-transfer.png";

const TwoColImgHover = ({ services = null }, props) => {
  const ServicesList = [
    {
      Img: AirportTransfer,
      title: "Airport Transfer",
      description:
        "Enjoy hassle-free airport transfers with our reliable & efficient service.",
    },
    {
        Img: CorporateTransfer,
      title: "Corporate Transfer",
      description:
        "Make a professional impression with top-notch transfer services. ",
    },
    {
        Img: CityTransfer,
      title: "City Transfer",
      description:
        "Discover the city in comfort and style with our premium services.",
    },
    {
        Img: HotelTransfer,
      title: "Hotel Transfer",
      description:
        "Enjoy a stress-free start to your vacation with our seamless service.",
    },
  ];

  if (!services) services = ServicesList;
  return (
    <>
      <section className="two-col-hov-wrapper section-gap pb-0">
        <h3 className="section-title">Services we Provide</h3>
        <Container>
          <Row>
            {services.map((service, index) => (
              <Col lg={6}>
                <div className="single-col-hov">
                  <img src={service.Img} />
                  <div className="single-col-desc">
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TwoColImgHover;
