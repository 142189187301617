import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import InnerBannerNext from "../Components/InnerBannerNext";
import Modal from "react-bootstrap/Modal";
import { getRequest, postRequest } from "../Helpers/APIHelper";
import ForSeo from "../Components/seo/ForSeo";

const BookForm = ({ title, desc, setting }) => {
  const [btnDisable, setBtnDisable] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(true);
  const [isReturnVisible, setIsReturnVisible] = useState(false);
  const [isMeetAndGreet, setIsMeetAndGreet] = useState(false);
  const [isReturnMeetAndGreet, setIsReturnMeetAndGreet] = useState(false);
  const [passengerOptions, setPassengerOptions] = useState([]);
  const [luggageOptions, setLuggageOptions] = useState([]);
  const [childOptions, setChildOptions] = useState([]);

  // -------for-seo-----
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  const currentURL = window.location.pathname;

  useEffect(() => {
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };
  // ----------------------

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleVisibility1 = () => {
    setIsVisible1(!isVisible1);
  };

  const toggleMeetAndGreet = () => {
    setIsMeetAndGreet(!isMeetAndGreet);
  };

  const toggleReturnMeetAndGreet = () => {
    setIsReturnMeetAndGreet(!isReturnMeetAndGreet);
  };

  const toggleReturnVisibility = () => {
    setIsReturnVisible(!isReturnVisible);
  };

  console.log(isReturnVisible);

  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const [quote, setQuote] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selected_fleet, setSelectedFleet] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const selected_fleet_id = JSON.parse(
      localStorage.getItem("selected_fleet_id")
    );
    var quote = JSON.parse(localStorage.getItem("quote"));
    setQuote(quote);

    if (quote?.is_from_airport === 1) {
      setIsVisible1(true);
    } else {
      setIsVisible1(false);
    }

    if (quote?.is_return_airport === 1) {
      setIsReturnVisible(true);
    } else {
      setIsReturnVisible(false);
    }

    const filteredData =
      quote.fleets.filter(
        (item) => item.fleet_category_id === selected_fleet_id
      )[0] ?? null;
    setSelectedFleet(filteredData);

    setFormData({
      ...formData,
      quote_id: quote.quote_id,
      fleet_id: filteredData?.fleet_category_id ?? "",
      total_fare: filteredData?.total ?? 0,
    });

    let passengerOptionsDef = Array.from(
      { length: filteredData.max_passenger },
      (_, index) => ({
        value: index + 1,
        label: index + 1,
      })
    );
    setPassengerOptions(passengerOptionsDef);

    let luggageOptionsDef = Array.from(
      { length: filteredData.max_luggage },
      (_, index) => ({
        value: index + 1,
        label: index + 1,
      })
    );
    setLuggageOptions(luggageOptionsDef);
    let childOptionsDef = Array.from(
      { length: filteredData.max_baby_seat },
      (_, index) => ({
        value: index + 1,
        label: index + 1,
      })
    );
    setChildOptions(childOptionsDef);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnDisable(true);
    formData.extras = {
      passenger: formData?.passenger ?? 1,
      luggage: formData?.luggage ?? 0,
      baby_seat: formData?.baby_seat ?? 0,
    };
    if (isMeetAndGreet) {
      formData.extras = {
        ...formData.extras,
        meet_and_greet: true,
      };
    }
    if (isReturnMeetAndGreet) {
      formData.extras = {
        ...formData.extras,
        return_meet_and_greet: true,
      };
    }
    localStorage.setItem("booking_data", JSON.stringify(formData ?? ""));

    postRequest("quote/confirmation", formData)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          localStorage.setItem(
            "fare_data",
            JSON.stringify(response?.data ?? "")
          );
          navigate("/confirmation");
        } else {
          if (response?.errors ?? false) {
            alert(response?.message ?? "");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? title}
        description={seo?.description ?? desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={siteURL ?? ""}
      />
      {btnDisable ? <div className="loading"></div> : ""}
      {/* <NavBar SeoTitle={title} SeoDesc={desc} /> */}
      <InnerBannerNext quote={quote} fleet={selected_fleet} />

      <div class="booking-form-wrapper section-gap">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="booking-form">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <h4 className="mt-0">Personal Details</h4>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Contact Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <h4>
                        Capacity & Extras
                        <label class="switch">
                          <input type="checkbox" checked={isVisible2} />
                          <span
                            class="slider"
                            onClick={toggleVisibility2}
                          ></span>
                        </label>
                      </h4>
                    </Col>
                    {isVisible2 && (
                      <>
                        <Col lg={4}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              as="select"
                              name="passenger"
                              value={formData?.passenger ?? 0}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Passenger</option>
                              {passengerOptions.map(({ value, label }) => (
                                <option value={value}>{label}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              as="select"
                              name="luggage"
                              value={formData?.luggage ?? 0}
                              onChange={handleChange}
                            >
                              <option value="0">Luggage</option>
                              {luggageOptions.map(({ value, label }) => (
                                <option value={value}>{label}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col lg={4}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              as="select"
                              name="baby_seat"
                              value={formData?.baby_seat ?? 0}
                              onChange={handleChange}
                            >
                              <option value="0">Child Seat</option>
                              {childOptions.map(({ value, label }) => (
                                <option value={value}>{label}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    {quote?.is_from_airport == 1 && (
                      <>
                        <Col lg={12}>
                          <h4>
                            Flight Information{" "}
                            <label class="switch">
                              <input checked={isVisible1} type="checkbox" />
                              <span
                                class="slider"
                                onClick={toggleVisibility1}
                              ></span>
                            </label>
                          </h4>
                        </Col>
                        {isVisible1 && (
                          <Row>
                            <>
                              <Col className="mb-3" lg={3}>
                                <Form.Control
                                  placeholder="Airlines"
                                  aria-label="Airlines"
                                  name="airlines"
                                  value={formData.airlines}
                                  onChange={handleChange}
                                  required
                                />
                              </Col>
                              <Col className="mb-3" lg={3}>
                                <Form.Control
                                  placeholder="Flight Name"
                                  aria-label="Flight Name"
                                  name="flight_name"
                                  value={formData.flight_name}
                                  onChange={handleChange}
                                  required
                                />
                              </Col>
                              <Col className="mb-3" lg={3}>
                                {/* <InputGroup className="mb-3"> */}
                                <Form.Control
                                  placeholder="Flight Number"
                                  aria-label="Flight Number"
                                  name="flight_number"
                                  value={formData.flight_number}
                                  onChange={handleChange}
                                  required
                                />
                                {/* <InputGroup.Text>
                                <Link to="" className="text-link">
                                  Verify
                                </Link>
                              </InputGroup.Text> */}
                                {/* </InputGroup> */}
                              </Col>
                              <Col lg={3}>
                                <h4>
                                  Meet And Greet{" "}
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span
                                      class="slider"
                                      onClick={toggleMeetAndGreet}
                                    ></span>
                                  </label>
                                </h4>
                              </Col>
                            </>
                          </Row>
                        )}
                      </>
                    )}

                    {quote?.is_return_airport == 1 && (
                      <div>
                        <Col lg={12}>
                          <h4>
                            Return Flight Information{" "}
                            <label class="switch">
                              <input
                                checked={isReturnVisible}
                                type="checkbox"
                              />
                              <span
                                class="slider"
                                onClick={toggleReturnVisibility}
                              ></span>
                            </label>
                          </h4>
                        </Col>
                        {isReturnVisible && (
                          <Row>
                            <>
                              <Col lg={3}>
                                <Form.Control
                                  placeholder="Return Airlines"
                                  aria-label="Airlines"
                                  name="return_airlines"
                                  value={formData.return_airlines}
                                  onChange={handleChange}
                                  required
                                />
                              </Col>
                              <Col lg={3}>
                                <Form.Control
                                  placeholder="Return Flight Name"
                                  aria-label="Flight Name"
                                  name="return_flight_name"
                                  value={formData.return_flight_name}
                                  onChange={handleChange}
                                  required
                                />
                              </Col>
                              <Col lg={3}>
                                {/* <InputGroup className="mb-3"> */}
                                <Form.Control
                                  placeholder="Return Flight Number"
                                  aria-label="Flight Number"
                                  name="return_flight_number"
                                  value={formData.return_flight_number}
                                  onChange={handleChange}
                                  required
                                />
                                {/* <InputGroup.Text>
                                <Link to="" className="text-link">
                                  Verify
                                </Link>
                              </InputGroup.Text> */}
                                {/* </InputGroup> */}
                              </Col>
                              <Col lg={3}>
                                <h4>
                                  Meet And Greet{" "}
                                  <label class="switch">
                                    <input type="checkbox" />
                                    <span
                                      class="slider"
                                      onClick={toggleReturnMeetAndGreet}
                                    ></span>
                                  </label>
                                </h4>
                              </Col>
                            </>
                          </Row>
                        )}
                      </div>
                    )}

                    <Col lg={12}>
                      <h4>
                        Booking For Someone Else
                        <label class="switch">
                          <input type="checkbox" />
                          <span
                            class="slider"
                            onClick={toggleVisibility}
                          ></span>
                        </label>
                      </h4>
                    </Col>

                    {isVisible && (
                      <>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Lead Passenger Name"
                              name="lead_passenger_name"
                              value={formData.lead_passenger_name}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Lead Passenger Number"
                              name="lead_passenger_phone"
                              value={formData.lead_passenger_phone}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col lg={12}>
                      <h4>Note To Driver</h4>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Control
                          as="textarea"
                          placeholder="Your Message to Driver"
                          rows="3"
                          name="driver_note"
                          value={formData.driver_note}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <h4>Payment</h4>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Select
                          name="payment_method"
                          value={formData.payment_method}
                          onChange={handleChange}
                          required
                        >
                          <option selected disabled value="">
                            Select Your Payment Type
                          </option>
                          {setting?.payments_options?.map((method, index) => (
                            <option key={index} value={method}>
                              {capitalizeFirstLetter(method)}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    {formData?.payment_method === "card" && (
                      <>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Card Number"
                              name="card_number"
                              value={formData?.card_number}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Card Expiry (MM/YY)"
                              name="card_expiry"
                              value={formData?.card_expiry}
                              onChange={(e) => {
                                let formattedValue = e.target.value.replace(
                                  /[^0-9]/g, // Remove non-numeric characters
                                  ""
                                );

                                // Ensure the first two digits are not greater than 12
                                const month = parseInt(
                                  formattedValue.substring(0, 2),
                                  10
                                );
                                if (month > 12) {
                                  formattedValue =
                                    "12" + formattedValue.substring(2);
                                }

                                // Format the input as MM/YY while the user types
                                if (formattedValue.length <= 4) {
                                  const formattedExpiry =
                                    formattedValue.replace(
                                      /(\d{2})(\d{0,2})/,
                                      "$1/$2"
                                    );
                                  handleChange({
                                    target: {
                                      name: "card_expiry",
                                      value: formattedExpiry,
                                    },
                                  });
                                }
                              }}
                              onKeyDown={(e) => {
                                // Allow backspace to remove characters
                                if (
                                  e.key === "Backspace" &&
                                  e.target.value.length === 3
                                ) {
                                  handleChange({
                                    target: {
                                      name: "card_expiry",
                                      value: e.target.value.slice(0, -1),
                                    },
                                  });
                                }
                              }}
                              pattern="\d{2}/\d{2}"
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={3}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="CVC"
                              minLength={3}
                              maxLength={4}
                              name="card_verification_code"
                              value={formData.card_verification_code}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Card Holder Name"
                              name="card_holder_name"
                              value={formData.card_holder_name}
                              onChange={handleChange}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Col lg="12">
                    <p>
                      By clicking the "Submit" button, you agree to Our{" "}
                      <Link
                        className="text-link"
                        to="/terms-of-services"
                        target="_blank"
                      >
                        Terms of services
                      </Link>{" "}
                      &
                      <Link
                        className="text-link"
                        to="/privacy-policy"
                        target="_blank"
                      >
                        &nbsp; Privacy Policy
                      </Link>
                    </p>
                  </Col>
                  <Col lg={{ span: 4, offset: 4 }} className="pt-4">
                    {/* <Button variant="main" onClick={handleShow}> */}
                    <button className="custom-btn" variant="main" type="submit">
                      Submit
                    </button>
                    {/* <Button className="btn-main" variant="main">
                <a style={{ textDecoration: "none" }} href="/">
                  {" "}
                  Get a Quote
                </a>
              </Button> */}
                  </Col>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal show={show} className="centered-modal" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm Your Booking.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please Confirm that all the information you provided are correct.
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-sm btn-outline" onClick={handleClose}>
            Close
          </button>
          <button className="btn-sm btn-outline" onClick={handleSubmit}>
            Confirm
          </button>
          {/* <Link
            to="/thank-you"
            className="btn-sm btn-main"
            onClick={handleClose}
          >
            Confirm
          </Link> */}
        </Modal.Footer>
      </Modal>
      {/* <Footer /> */}
    </>
  );
};

export default BookForm;
