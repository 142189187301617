import image from "../../Assets/page_not_found.svg";
import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <img width={400} src={image} alt="not-found-img" />
        <p style={{ textAlign: "center", marginTop: "10px" }}>Page Not found</p>
      </div>
    </div>
  );
};

export default NotFound;
