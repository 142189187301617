import React, { useEffect, useState } from "react";
import { Col, Container, Row, TabContainer } from "react-bootstrap";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import { getRequest } from "../Helpers/APIHelper";
import ForSeo from "../Components/seo/ForSeo";
import { useHref } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Terms = (props) => {
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  useEffect(() => {
    getSeo();
  }, []);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title ?? props?.title}
        description={seo?.description ?? props?.desc}
        keywords={seo?.keywords ?? ""}
        siteUrl={canonicalURL ?? ""}
      />
      <div className="inner-banner">
        <Container>
          <Row>
            <Col
              style={{ minHeight: "100vh" }}
              lg="12"
              className="terms-wrapper"
            >
              <h1>Terms of Services</h1>
              <div
                dangerouslySetInnerHTML={{ __html: props?.termsContent ?? "" }}
              ></div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Terms;
